import {
    SET_CUSTOMER_LIST,
    SET_USER_LIST,
    SET_AVAILABLE_ACCOUNTS,
    SET_STORES,
    SET_COL_MAP,
    SET_VAL_MAP,
    SET_BIN_TEMPLATES,
    SET_IDEAL_BINS,
    CLEAR_IDEAL_BINS,
    SET_REASSORT_RESULTS,
    SET_ASSET_PAGES
} from './types'

export default function(state={}, action) {
    switch (action.type) {
        case SET_CUSTOMER_LIST: {
            return {...state, customerList: action.payload ? action.payload.sort((a,b) => a.name==b.name ? 0 : (a.name<b.name ? -1 : 1)) : action.payload}
        }
        case SET_USER_LIST: {
            return {...state, userList: action.payload}
        }
        case SET_AVAILABLE_ACCOUNTS: {
            return {...state, availableAccountList: action.payload}
        }
        case SET_STORES: {
            return {...state, stores: action.payload}
        }
        case SET_COL_MAP: {
            return {...state, customerColumnMap: action.payload}
        }
        case SET_VAL_MAP: {
            return {...state, customerValueMap: action.payload}
        }
        case SET_BIN_TEMPLATES: {
            return {...state, binTemplates: action.payload}
        }
        case SET_IDEAL_BINS: {
            return {...state, idealBins: {...(state.idealBins ?? {}), [action.templateId]: action.payload}}
        }
        case CLEAR_IDEAL_BINS: {
            return {...state, idealBins: null}
        }
        case SET_REASSORT_RESULTS: {
            return {...state, reassortOutput: action.payload}
        }
        case SET_ASSET_PAGES: {
            return {...state, assetPages: action.payload}
        }
        default: {
            return state;
        }
    }
}