import React from "react"
import {ReactComponent as Certificate} from '../../assets/icons/certificate-7.svg'

export default function CertIcon({stone}) {
    if ((stone.lab !== 'GIA' && stone.lab !== 'GIA-LG') || stone.lightPerformance == null) return <Certificate />
    console.log('CertIcon', stone.lab, stone.certNo, stone.lightPerformance)
    return <span className='annotated-icon'>
        <Certificate />
        +
    </span>
}