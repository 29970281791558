import FileSaver from 'file-saver'
const Excel = require('exceljs')

export const templateSplitter = /\{([\w\d.,_-\s+/]+)\}(?:\||$)/g
export function getBinRanges(template, bins=[]) {
    const attributes = [...template.templateString.matchAll(templateSplitter)].map(res => res[1])
    attributes.unshift('storeId')
    const neededValues = attributes.reduce((obj, attr) => ({...obj, [attr]: new Set()}), {})
    for (const bin of bins) {
        const binValues = [...bin.binSpec.matchAll(templateSplitter)].map(res => res[1])
        for (let i = 1; i < attributes.length; i++) {
            neededValues[attributes[i]].add(binValues[i-1])
        }
        neededValues.storeId.add(bin.storeId)
    }
    for (const attr of attributes) {
        neededValues[attr] = [...neededValues[attr]]
    }
    if (neededValues.weight) neededValues.weight.sort()
    if (neededValues.colour) neededValues.colour.sort()
    return {attributes, neededValues}
}

export const normalColumnOrder = [
    {key: 'storeName', header: 'Store Name'},
    {key: 'tier', header: 'Tier'},
    {key: 'lab', header: 'Lab'},
    {key: 'shape', header: 'Shape'},
    {key: 'fluorescence', header: 'Flu'},
    {key: 'cut', header: 'Cut'},
    {key: 'polish', header: 'Pol'},
    {key: 'symmetry', header: 'Sym'},
    {key: 'cell1', header: 'Unknown Column 1'},
    {key: 'cell2', header: 'Unknown Column 2'},
    {key: 'weight', header: 'Weight'},
    {key: 'colour', header: 'Colour'},
    {key: 'clarity', header: 'Clarity'},
    {key: 'targetQty', header: 'Count'}
]

const colRegex = /^\s*([A-Z,\s]+)\s*$/
const colRegex2 = /^\s*([A-Z])-+([A-Z])\s*$/
const claritySplitter = /[,\s&\/]+/g
const clarityRegex2 = /^([A-Z]+\d?)-([A-Z]+\d?)$/
function parseCCHeaders(cell1, cell2, {colours, clarities}) {
    const col11 = cell1.match(colRegex), col12 = cell1.match(colRegex2)
    const col21 = cell2.match(colRegex), col22 = cell2.match(colRegex2)
    if (col11 || col12) {}
    else if (col21 || col22) {
        [cell1, cell2, col11, col21, col12, col22] = [cell2,cell1, col21, col11, col22, col12]
    }
    else return {}
    let colourArray = []
    if (col11) {
        for (let i = 0; i < col11[1].length; i++) {
            if (colours.some(c => c.colour === col11[1].charAt(i))) colourArray.push(col11[1].charAt(i))
        }
    } else if (col12) {
        const minColour = colours.find(c => c.colour === col12[1]), maxColour =  colours.find(c => c.colour === col12[2])
        if (minColour && maxColour) colourArray = colours.filter(c => c.order >= minColour.order && c.order <= maxColour.order)
    }
    let clarityArray = []
    for (const clr of cell2.split(claritySplitter)) {
        const exactClr = clarities.find(c => c.clarity === clr)
        if (exactClr) clarityArray.push(exactClr)
        else {
            const clrRange = clr.match(clarityRegex2)
            if (clrRange) {
                const minClr = clarities.find(c => c.clarity === clrRange[1]), maxClr = clarities.find(c => c.clarity === clrRange[2])
                if (minClr && maxClr) clarityArray = clarityArray.concat(clarities.filter(c => c.order >= minClr.order && c.order <= maxClr.order))
            } else {
                if (!isNaN(clr) && clarityArray.length) {
                    const lastClr = clarityArray[clarityArray.length-1]
                    const thisClr = clarities.find(c => c.clarity === (lastClr.clarity.slice(0, -1)+clr))
                    if (thisClr) clarityArray.push(thisClr)
                } else {
                    let includeBetter = false, checkClr = clr
                    if (clr.endsWith('+')) {
                        includeBetter = true
                        checkClr = clr.slice(0, -1)
                    }
                    const partialMatches = clarities.filter(c => c.clarity.startsWith(checkClr))
                    if (partialMatches.length) {
                        clarityArray = clarityArray.concat(partialMatches)
                        if (includeBetter) {
                            const firstFound = partialMatches.reduce((min, c) => min == null || c.order < min ? c.order : min, null)
                            clarityArray = clarityArray.concat(clarities.filter(c => c.order < firstFound))
                        }
                    }
                }
            }
        }
    }
    clarityArray = [...(new Set(clarityArray.map(c => c.clarity)))]
    return {colour: colourArray.join(','), clarity: clarityArray.join(',')}
}

const wgtRegex = /^\s*(\d*\.?\d*)\s*-+\s*(\d*\.?\d*)\s*$/
const wgtRegex2 = /^\s*(\d*\.?\d*)\s*\+\s*$/
function parseWgtHeader(str) {
    const rangeMatch = str.match(wgtRegex)
    if (rangeMatch) {
        let minWgt = Number(rangeMatch[1]), maxWgt = Number(rangeMatch[2])
        if (isNaN(minWgt) || isNaN(maxWgt)) return null
        if (Math.abs(maxWgt - Math.round(maxWgt*10)/10) < 0.000001) maxWgt -= 0.01
        return `${minWgt}-${maxWgt}`
    } else {
        const plusMatch = str.match(wgtRegex2)
        const minWgt = Number(plusMatch[1])
        if (isNaN(minWgt)) return null
        return `${minWgt}-10`
    }
}

function promiseReadBinaryFile(f) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.onloadend = () => resolve(fr.result)
        fr.onabort = reject
        fr.onerror = reject
        fr.readAsArrayBuffer(f)
    })
}

export async function parseCrosstabs(file, attributes) {
    const buffer = await promiseReadBinaryFile(file)
    const book = new Excel.Workbook()
    await book.xlsx.load(buffer)
    const bins = []

    book.eachSheet(sheet => {
        const storeName = (sheet.name && sheet.name.toLowerCase().replace(/\s/g, '')!=='sheet1') ? sheet.name : null
        const cornerCells = []
        sheet.eachRow((row, rowNum) => {
            if (rowNum <= 2) return
            row.eachCell((cell, colNum) => {
                if (cell.value == 'Weight Maps') cornerCells.push({rowNum: rowNum-2, colNum})
            })
        })

        for (const {rowNum, colNum} of cornerCells) {
            const cornerAttributes = {}
            const corner1 = sheet.getCell(rowNum, colNum), corner2 = sheet.getCell(rowNum+1, colNum)
            if (corner1.value) cornerAttributes.cell1 = corner1.value
            if (corner2.value) cornerAttributes[`cell${corner1.value ? '2' : '1'}`] = corner2.value
            let c = colNum + 1
            while (sheet.getCell(rowNum+1, c)?.value && sheet.getCell(rowNum+2, c).value != 'Totals') {
                let r = rowNum + 3
                const h1 = sheet.getCell(rowNum, c), h2 = sheet.getCell(rowNum+1, c)
                const {colour, clarity} = parseCCHeaders(h1?.value, h2?.value, attributes)
                while (sheet.getCell(r, colNum)?.value && sheet.getCell(r, colNum).value != 'Totals') {
                    const lft = sheet.getCell(r, colNum)
                    const weight = parseWgtHeader(lft?.value)
                    if (colour!=null && clarity!=null && weight!=null) {
                        const targetQty = sheet.getCell(r, c)?.value || 0
                        bins.push({...cornerAttributes, storeName, weight, colour, clarity, targetQty})
                    }
                    r++
                }
                c++
            }
        }
    })

    const cornerValues = ['cell1','cell2'].reduce((obj,key) => ({...obj, [key]: [...new Set(bins.filter(b => b[key]).map(b => b[key]))]}), {})
    for (const [k, arr] of Object.entries(cornerValues)) {
        const indShapeMap = new Map([
            ['Fancy','OV,CU,EM,MQ,PS,PR,HS,RAD'], 
            ['Fancies','OV,CU,EM,MQ,PS,PR,HS,RAD'], 
            ['Ideal Fancies','XP,IDC,IDO,IDH'], 
            ['Ideals','XP,IDC,IDO,IDH'], 
            ['Ideal','XP,IDC,IDO,IDH']
        ])
        const shapeValueMap = arr.reduce((map, val) => {
            const valArr = val.split(/[,&\/]+/)
            let mappedValArr = valArr.map((valArrVal) => {
                const v = valArrVal.trim()
                if (!indShapeMap.has(v)) {
                    const shape = attributes.shapes.find(shp => shp.shapeCode === v || shp.description === v || shp.description+'s' === v || shp.description+'es' === v)
                    if (shape) indShapeMap.set(v, shape.shapeCode)
                }
                return indShapeMap.has(v) ? {value: indShapeMap.get(v), found: 1} : {value: v, found: 0}
            })
            mappedValArr = mappedValArr.filter(obj => obj.value)
            return map.set(val, mappedValArr.reduce((obj, {value, found}) => ({value: obj.value ? obj.value+','+value : value, found: obj.found + found/mappedValArr.length}), {value: '', found: 0}))
        }, new Map())
        const shapeMatches = [...shapeValueMap].reduce((count,[_,{found}]) => count + found, 0)
        if (shapeMatches > shapeValueMap.size/2) {
            for (const bin of bins) {
                if (shapeValueMap.get(bin[k])) {
                    bin.shape = shapeValueMap.get(bin[k]).value
                }
                delete bin[k]
            }
            continue
        }
        const fluMatches = arr.reduce((count, val) => count + attributes.fluorescences.some(flu => flu.description == val || flu.fluorescence == val), 0)
        if (fluMatches > arr.length/2) {
            for (const bin of bins) {
                bin.fluorescence = bin[k]
                delete bin[k]
            }
            continue
        }
        const labs = ['GIA','AGS','AGSL','FM','IGI','IIDGR','HRD']
        const labMatches = arr.reduce((count, val) => count + labs.includes(val), 0)
        if (labMatches > arr.length/2) {
            for (const bin of bins) {
                bin.lab = bin[k]
                delete bin[k]
            }
            continue
        }
    }
    return bins
}

export function saveFlatIdeal(flatData) {
    const columns = normalColumnOrder.filter(col => flatData.some(row => row[col.key]))
    const workbook = new Excel.Workbook();
    workbook.creator = 'Dialog';
    workbook.created = new Date();
    const sheet = workbook.addWorksheet('Ideal Inventory');
    sheet.columns = columns
    sheet.addRows(flatData)
    sheet.views = [{ state: 'frozen', ySplit: 1 }]
    sheet.autoFilter = {
        from: 'A1',
        to: {column: columns.length, row: flatData.length+1}
    }
    return workbook.xlsx.writeBuffer()
    .then(buffer => {
        FileSaver.saveAs(new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `Dialog Ideal Inventory.xlsx`);
    })
}

export function saveDialogIdealFlat(bins) {
    let columns = normalColumnOrder.filter(col => bins.some(row => row[col.key])) // doesn't work because bin properties are still in the binSpec string
    columns = columns.concat([
        {header: 'Qty On Hand', key: 'currentQty'},
        {header: 'Cts On Hand', key: 'currentWeight'},
        {header: 'Value On Hand', key: 'currentValue'},
        {header: 'Qty Deployed', key: 'currentDeployedQty'},
        {header: 'Cts Deployed', key: 'currentDeployedWeight'},
        {header: 'Value Deployed', key: 'currentDeployedValue'}
    ])
    for (let yr = 2019; yr <= new Date().getFullYear(); yr++) {
        columns = columns.concat([
            {header: `${yr} Average`, key: `${yr}Avg`},
            {header: `${yr} Sold`, key: `${yr}Sold`},
            {header: `${yr} New`, key: `${yr}New`},
            {header: `${yr} Returned`, key: `${yr}Ret`}
        ])
    }
    const workbook = new Excel.Workbook();
    workbook.creator = 'Dialog';
    workbook.created = new Date();
    const sheet = workbook.addWorksheet('Ideal Inventory');
    sheet.columns = columns
    const flatData = bins.map(bin => (Array.isArray(bin.stones) ? {
        ...bin, 
        ...bin.stones.reduce((obj,stone) => ({avQty: obj.avQty+1, avWgt: obj.avWgt+Number(stone.weight), avValue: obj.avValue+Number(stone.price ?? 0)}), {avQty: 0, avWgt: 0, avValue: 0})
    } : bin))
    sheet.addRows(flatData)
    sheet.views = [{ state: 'frozen', ySplit: 1 }]
    sheet.autoFilter = {
        from: 'A1',
        to: {column: columns.length, row: flatData.length+1}
    }
    return workbook.xlsx.writeBuffer()
    .then(buffer => {
        FileSaver.saveAs(new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `Dialog Ideal Inventory.xlsx`);
    })
}
