import React, {Component} from 'react';
import {connect} from 'react-redux'
import * as actions from '../actions'
import NumberFormat from 'react-number-format';
import Modal from 'react-responsive-modal'
import ChangePassword from './settings/password'
import InvoiceModal from './customer/invoice_modal'
import legendLogo from '../assets/dialog-legend-white.png'
import wa_qr from '../assets/whatsapp-qr.png'

class Footer extends Component {

    state = {
        changePasswordOpen: false,
        invoicesOpen: false
    };

    selectAssetPage = path => {
        this.props.selectEmbeddedTab(path)
        this.props.closeDrawer()
    }

    render() {
        return (
            <div className='profile-list'>
                <div className='profile-handle profile-open' onClick={this.props.closeDrawer}><i className='fas fa-caret-right'/></div>
                {this.props.customer ? <div className='profile-item profile-header'>{this.props.customer.legalName}</div> : null}
                {this.props.customer && this.props.customer.legend ? <div className='profile-item profile-legend'><img src={legendLogo} /></div> : null}
                {this.props.customer && this.props.customer.minInventory ? <div className='profile-item'>
                    <span style={{fontWeight: 400}}>Minimum: </span>
                    <NumberFormat displayType='text' value={this.props.customer.minInventory} decimalScale={2} prefix='$' thousandSeparator={true} />
                </div> : null}
                {this.props.customer && this.props.customer.currentlyHolding ? <div className='profile-item'>
                    <span style={{fontWeight: 400}}>Current: </span>
                    <NumberFormat displayType='text' value={this.props.customer.currentlyHolding} decimalScale={2} prefix='$' thousandSeparator={true} />
                </div> : null}
                {this.props.customer && this.props.customer.maxInventory ? <div className='profile-item'>
                    <span style={{fontWeight: 400}}>Maximum: </span>
                    <NumberFormat displayType='text' value={this.props.customer.maxInventory} decimalScale={2} prefix='$' thousandSeparator={true} />
                </div> : null}
                <div className='profile-item' style={{width: '100%'}}>
                    <button className='btn' onClick={() => this.setState({invoicesOpen: true})}><i className="fas fa-file-invoice"/>&nbsp;Invoices</button>
                </div>
                {this.props.assetPages.map(page => (
                    <div key={page.path} className='profile-item' style={{width: '100%'}}>
                        <button className='btn' onClick={() => this.selectAssetPage(page.path)}>{page.title}</button>
                    </div>
                ))}
                {/*TODO maybe this is a good place to put file upload*//*this.props.customer && this.props.userType === 'admin'*/}
                <div className='flexbox-filler' />
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', color: 'var(--colour-white)'}}>
                    <a href='https://wa.me/12366681532' target='_blank'><img style={{maxWidth: '100px'}} src={wa_qr}/></a>Contact Us
                </div>
                <a className='profile-item' onClick={() => this.setState({changePasswordOpen: true})}>Change Password</a>
                <Modal open={this.state.changePasswordOpen} onClose={() => this.setState({changePasswordOpen: false})} center>
                    <h4>Password Change</h4>
                    <ChangePassword username={this.props.username} changePassword={(values) => {this.props.changePassword(values).then(() => this.setState({changePasswordOpen: false}))}}/>
                </Modal>
                <Modal 
                    open={!!this.state.invoicesOpen} 
                    onClose={() => this.setState({invoicesOpen: false})} 
                    styles={{modal: {width: '60%', padding: '1rem'}}}
                    center
                >
                    <InvoiceModal customer={this.props.customer} />
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        username: state.user.currentUser?.username,
        customer: state.user.customer,
        userType: state.user.currentUser?.administrator ? 'admin' : (state.user.currentUser?.salesRep ? 'rep' : 'user'),
        assetPages: state.admin?.assetPages ?? []
    }
}

export default connect(mapStateToProps, actions)(Footer)