import React, {useState, useMemo, useEffect} from 'react'
import {connect} from 'react-redux'
import {/*Switch, Route, */Redirect} from 'react-router-dom'
import Sidebar from 'react-sidebar'
import Modal from 'react-responsive-modal'
import ReactTooltip from 'react-tooltip'

import * as actions from '../actions'
import logo from '../assets/banner-white.png'
import '@fortawesome/fontawesome-free/css/all.css'
import 'react-table-6/react-table.css'
import '../style.css'
import Profile from './profile'
import Loading from './common/loading'
import ConfirmModal from './common/confirm_modal'
import { getTotalsText } from './common/utils'

import AvailableInventory from './inventory/available'
import MyInventory from './inventory/my'
import CartInventory from './inventory/cart'
import PastInventory from './inventory/past'
import StoneDetails from './inventory/details-2'

import AvailableJewellery from './jewellery/available'
import MyJewellery from './jewellery/my'
import CartJewellery from './jewellery/cart'
import PastJewellery from './jewellery/past'
import JewelleryDetails from './jewellery/details'

import AvailableLabGrown from './labGrown/available'
import MyLabGrown from './labGrown/my'
import CartLabGrown from './labGrown/cart'
import PastLabGrown from './labGrown/past'
import LabGrownDetails from './labGrown/details'

import ChangePassword from './settings/password'

import CustomerBase from './customer/customer_base'
import BillingList from './customer/billing_list'
import UserBase from './user/user_base'
import IdealInventory from './customer/ideal_inventory'
import ListIcon from './common/list-icon'
import ErrorBoundary from './common/error_boundary'
import AssetFileFrame from './assetFileFrame'

const defaultTabs = [
    {name: 'My Inventory', icon: 'archive-3', groupKey: 'my', group: [
        {name: 'Loose Diamonds', default: true, component: MyInventory, showForStore: 'allInventory'},
        {name: 'Clarity LG Diamonds', component: MyLabGrown, showForStore: 'allLabGrown'},
        {name: 'Core Jewellery', component: MyJewellery, showForStore: 'allJewellery'}
    ]},
    {name: 'Available', icon: 'diamond-4-alt', groupKey: 'available', group: [
        {name: 'Loose Diamonds', default: true, component: AvailableInventory, showForStore: 'allInventory'},
        {name: 'Clarity LG Diamonds', component: AvailableLabGrown, showForStore: 'allLabGrown'},
        {name: 'Core Jewellery', component: AvailableJewellery, showForStore: 'allModels'}
    ]}, 
    {name: 'Cart', icon: 'fa-shopping-cart', groupKey: 'cart', group: [
        {name: 'Loose Diamonds', default: true, component: CartInventory, showForStore: 'allInventory'},
        {name: 'Clarity LG Diamonds', component: CartLabGrown, showForStore: 'allLabGrown'},
        {name: 'Core Jewellery', component: CartJewellery, showForStore: 'allJewellery'}
    ]},
    {name: 'Account History', icon: 'archive-11', groupKey: 'past', group: [
        {name: 'Loose Diamonds', default: true, component: PastInventory, showForStore: 'allInventory'},
        {name: 'Clarity LG Diamonds', component: PastLabGrown, showForStore: 'allLabGrown'},
        {name: 'Core Jewellery', component: PastJewellery, showForStore: 'allJewellery'}
    ]},
    {name: 'Users', component: UserBase, rep: true, icon: 'user-29'},
    {name: 'Customers', component: CustomerBase, admin: true, icon: 'shop-3'},
    {name: 'Billing', component: BillingList, admin: true, icon: 'credit-card-5'},
    {name: 'Reassort', component: IdealInventory, rep: true, icon: 'fa-random'}
]
const endLinks = [
    {name: 'customerSelect', rep: true},
    {name: 'Refer a Friend', icon: 'fa-gift', href: '/referral?name=${userFirstName}&email=${userEmail}'},
    {name: 'Reports', rep: true, icon: 'fa-file-excel', confirmModal: {
        confirm: 'getAllReports',
        message: `Generate a set of fresh Dialog reports? This takes 1-2 minutes.`
    }},
    {name: 'Sync', admin: true, icon: 'sync', confirmModal: {
        confirm: 'sync',
        message: `Check DX for new data? This can take a few minutes.`
    }},
    {name: 'Log Out', action: 'signoutUser', last: true, icon: 'power-on'}
]

const parcelExp = /[\?\&]parcel=([\w\-%]+).*\&cert=([\w\s%]+)/
const pageExp = /[\?\&]page=([\w\-\s%]+)/

function AppBase(props) {

    const [checkingAuth, setCheckingAuth] = useState(false)
    const [authCheckFailed, setAuthCheckFailed] = useState(false)
    const [profileOpen, setProfileOpen] = useState(false)

    useEffect(() => {
        if (!props.authenticated) {
            if (!checkingAuth) {
                setCheckingAuth(true);
                props.confirmSignin()
                .catch((err) => {
                    setAuthCheckFailed(true)
                    return Promise.reject(err)
                })
                .then(() => props.getAttributes())
            }
        }
        else {
            props.getAttributes();
            props.getCustomer();
            props.getCurrentInventory();
            props.getCurrentJewellery();
            props.getCurrentLabGrown();
            props.getAvailableInventory();
            props.getAvailableJewellery();
            props.getAvailableJewelleryModels();
            props.getAvailableLabGrown();
            props.getCart();
            props.getJewelleryCart();
            props.getLabGrownCart();
            props.getPastInventory();
            props.getPastJewellery();
            props.getPastLabGrown();
            props.getAssetPages();
            if (props.administrator || props.salesRep) {
                props.getAllCustomers();
            }
        }
    }, [])

    useEffect(() => {
        if (!props.authenticated) {
            if (!checkingAuth) {
                setCheckingAuth(true);
                props.confirmSignin()
                .catch(() => setAuthCheckFailed(true))
            }
        }
        else if (checkingAuth) {
            setCheckingAuth(false);
        }
    }, [props.authenticated, checkingAuth])

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [props.extraTabList])

    const showDefaultTabs = useMemo(() => defaultTabs.filter(tab => (!tab.admin || props.administrator) && (!tab.rep || props.salesRep || props.administrator)), [props.administrator, props.salesRep]);
    const showEndLinks = useMemo(() => endLinks.filter(tab => (!tab.admin || props.administrator) && (!tab.rep || props.salesRep || props.administrator)), [props.administrator, props.salesRep]);
    const subItemsByGroup = useMemo(() => showDefaultTabs.filter(tab => tab.group).reduce((obj, tab) => ({
        ...obj,
        [tab.groupKey]: tab.group.reduce((group, subItem) => {
            const stock = props?.[subItem.showForStore]?.[tab.groupKey]
            if (stock?.length) {
                if (tab.groupKey === 'available' && subItem.showForStore === 'allModels') {
                    group.push({...subItem, ...stock.reduce(({pieceCount, totalValue}, model) => ({pieceCount: pieceCount + model.availableTotalQty, totalValue: totalValue + (model.availableTotalQty*model.total)}), {pieceCount: 0, totalValue: 0})})
                } else {
                    group.push({...subItem, pieceCount: stock.length, totalValue: stock.reduce((tot, stone) => tot + stone.total, 0)})
                }
            }
            return group
        }, [])
    }), {}), [showDefaultTabs, props.allInventory?.available, props.allInventory?.my, props.allInventory?.cart, props.allInventory?.past, props.allJewellery?.available, props.allJewellery?.my, props.allJewellery?.cart, props.allJewellery?.past, props.allLabGrown?.available, props.allLabGrown?.my, props.allLabGrown?.cart, props.allLabGrown?.past])

    const removeTab = (parcelName) => {
        if (props.currentTab==parcelName) {
            const idx = (props.extraTabList||[]).findIndex(tab => tab.parcelName==parcelName);
            if (idx > 0) props.selectTab(props.extraTabList[idx-1].parcelName);
            else props.selectTab(showDefaultTabs[showDefaultTabs.length-1].name);
        }
        props.removeTab(parcelName);
    }

    const renderNavItem = (item) => {
        if (item.name==='customerSelect' && Array.isArray(props.customerList)) return (
            <div
                key={item.name}
                className='nav-item'
                style={item.last ? {paddingBottom: '1rem'} : {}}
            >
                <select 
                    value={props.currentCustomerId} 
                    onChange={(e) => {
                        props.setMyCustomer(props.customerList.find(c => c.customerId==e.target.value));
                        window.location.reload();
                    }}
                >
                    {
                        props.customerList.map(customer => (
                            <option key={customer.customerId} value={customer.customerId}>{customer.customerId} - {customer.name}</option>
                        ))
                    }
                </select>
            </div>
        )
        else if (item.parcelName && item.type==='J') {
            const tipProps = {}
            return (
                <div
                    key={item.parcelName}
                    className={item.parcelName===props.currentTab ? 'nav-item selected' : 'nav-item'} 
                    style={item.last ? {paddingBottom: '1rem'} : {}}
                    onClick={() => props.selectTab(item.parcelName)}
                >
                    <div className='nav-item__container' {...tipProps}>
                        <ListIcon icon='drop-earrings' selected={item.parcelName===props.currentTab} />
                        <div className='nav-item_stone'>
                            <span>{item.header}&nbsp;</span>
                            <button className='tab-button' onClick={(e) => {
                                e.stopPropagation();
                                removeTab(item.parcelName);
                            }}>
                                <i className="fas fa-times-circle"/>
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else if (item.parcelName && item.type==='LG') {
            let foundParcel = null
            if (props.allLabGrown.details && props.allLabGrown.details[item.parcelname]) foundParcel = props.allLabGrown.details[item.parcelname]
            for (const arrayName of ['my', 'past', 'available']) {
                if (!foundParcel && props.allLabGrown[arrayName]) {
                    foundParcel = props.allLabGrown[arrayName].find(p => p.parcelName === item.parcelName)
                }
            }
            const tipProps = foundParcel ? {'data-tip': `${foundParcel.parcelName}<br/>${foundParcel.weight}ct ${foundParcel.shapeCode} ${foundParcel.colour} ${foundParcel.clarity}<br/>${foundParcel.cut || '-'} ${foundParcel.polish} ${foundParcel.symmetry} ${foundParcel.fluorescence}<br/>${foundParcel.status}`} : {}
            return (
                <div
                    key={item.parcelName}
                    className={item.parcelName===props.currentTab ? 'nav-item selected' : 'nav-item'} 
                    style={item.last ? {paddingBottom: '1rem'} : {}}
                    onClick={() => props.selectTab(item.parcelName)}
                >
                    <div className='nav-item__container' {...tipProps}>
                        <ListIcon icon='diamond-7' selected={item.parcelName===props.currentTab} />
                        <div className='nav-item_stone'>
                            <span>{item.header}&nbsp;</span>
                            <button className='tab-button' onClick={(e) => {
                                e.stopPropagation();
                                removeTab(item.parcelName);
                            }}>
                                <i className="fas fa-times-circle"/>
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else if (item.parcelName) {
            let foundParcel = null
            if (props.allInventory.details && props.allInventory.details[item.parcelName]) foundParcel = props.allInventory.details[item.parcelName]
            for (const arrayName of ['my', 'past', 'available']) {
                if (!foundParcel && props.allInventory[arrayName]) {
                    foundParcel = props.allInventory[arrayName].find(p => p.parcelName === item.parcelName)
                }
            }
            const tipProps = foundParcel ? {'data-tip': `${foundParcel.parcelName}<br/>${foundParcel.weight}ct ${foundParcel.shapeCode} ${foundParcel.colour} ${foundParcel.clarity}<br/>${foundParcel.cut || '-'} ${foundParcel.polish} ${foundParcel.symmetry} ${foundParcel.fluorescence}<br/>${foundParcel.status}`} : {}
            return (
                <div
                    key={item.parcelName}
                    className={item.parcelName===props.currentTab ? 'nav-item selected' : 'nav-item'} 
                    style={item.last ? {paddingBottom: '1rem'} : {}}
                    onClick={() => props.selectTab(item.parcelName)}
                >
                    <div className='nav-item__container' {...tipProps}>
                        <ListIcon icon='diamond-3' selected={item.parcelName===props.currentTab} />
                        <div className='nav-item_stone'>
                            <span>{item.header}&nbsp;</span>
                            <button className='tab-button' onClick={(e) => {
                                e.stopPropagation();
                                removeTab(item.parcelName);
                            }}>
                                <i className="fas fa-times-circle"/>
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else if (item.action) return (
            <div
                key={item.name}
                className={item.name===props.currentTab ? 'nav-item selected' : 'nav-item'} 
                style={item.last ? {paddingBottom: '1rem'} : {}}
                onClick={() => props[item.action]()}
            >
                <div className="nav-item__container" >
                    <ListIcon icon={item.icon} /> {item.name}
                </div>
            </div>
        )
        else if (item.confirmModal) return (
            <ConfirmModal 
                key={item.name}
                {...item.confirmModal}
                confirm={props[item.confirmModal.confirm]}
                trigger={onClick => (
                    <div 
                        className={item.name===props.currentTab ? 'nav-item selected' : 'nav-item'} 
                        style={item.last ? {paddingBottom: '1rem'} : {}}
                        onClick={onClick}
                    >
                        <div className="nav-item__container" >
                            <ListIcon icon={item.icon} /> {item.name}
                        </div>
                    </div>
                )}
            />
        )
        else if (item.href) return (
            <div
                key={item.name}
                className={item.name===props.currentTab ? 'nav-item selected' : 'nav-item'}
                style={item.last ? {paddingBottom: '1rem'} : {}}
                onClick={() => window.location.href = item.href.replaceAll(/\${(\w+)}/g, (_, prop) => props[prop])}
            >
                <div className="nav-item__container" >
                    <ListIcon icon={item.icon} /> {item.name}
                </div>
            </div>
        )
        else if (item.component) return (
            <div
                key={item.name}
                className={item.name===props.currentTab ? 'nav-item selected' : 'nav-item'} 
                style={item.last ? {paddingBottom: '1rem'} : {}}
                onClick={() => props.selectTab(item.name)}
            >
                <div className="nav-item__container" >
                    <ListIcon icon={item.icon} selected={item.name===props.currentTab} /> {item.name}
                </div>
            </div>
        )
        else if (item.group) {
            const subItems = subItemsByGroup[item.groupKey]
            return <React.Fragment key={item.name}>
                <div
                    className={`nav-item${props.currentTab.startsWith(item.name+'.') ? ' selected' : ''}${subItems.length ? ' bottom0' : ''}`}
                    style={item.last ? {paddingBottom: '1rem'} : {}}
                    onClick={() => props.selectTab(`${item.name}.${(subItems[0] || item.group.find(it => it.default))?.name}`)}
                >
                    <div className='nav-item__container'>
                        <ListIcon icon={item.icon} selected={props.currentTab.startsWith(item.name+'.')} /> {item.name}
                    </div>
                </div>
                {subItems.map((subItem, i) => (
                    <div
                        key={subItem.name}
                        className={`nav-item sub-item${props.currentTab===`${item.name}.${subItem.name}` ? ' selected' : ''}${i===subItems.length-1 ? '' : ' bottom0'}`}
                        onClick={() => props.selectTab(`${item.name}.${subItem.name}`)}
                    >
                        {subItem.name}
                        <span className='sub-item-summary'>{getTotalsText(subItem.pieceCount, subItem.totalValue)}</span>
                    </div>
                ))}
            </React.Fragment>
        } else return (
            <div
                key={item.name}
                className={item.name===props.currentTab ? 'nav-item selected' : 'nav-item'} 
                style={item.last ? {paddingBottom: '1rem'} : {}}
            >
                <div className='nav-item__container'>{item.name}</div>
            </div>
        )
    }

    if (parcelExp.test(props.location.search)) {
        const [, parcelName, certNo] = props.location.search.match(parcelExp).map(decodeURIComponent)
        props.addTab({parcelName, certNo})
        props.selectTab(parcelName)
        if (props.authenticated || !authCheckFailed)
            return <Redirect to="/" />
    } else if (pageExp.test(props.location.search)) {
        const [, page] = props.location.search.match(pageExp).map(decodeURIComponent)
        props.selectTab(page)
        if (props.authenticated || !authCheckFailed)
            return <Redirect to="/" />
    }
    if (!props.authenticated || authCheckFailed) {
        window.location.replace('/home.html')
        return null
    }

    const currentTab = showDefaultTabs.find(t => t.name==props.currentTab)
        || showDefaultTabs.find(t => t.group && props.currentTab.startsWith(t.name+'.'))?.group?.find(st => props.currentTab.endsWith('.'+st.name))
        || showEndLinks.find(t => t.name==props.currentTab)
        || (props.extraTabList||[]).find(t => t.parcelName==props.currentTab)
        || (props.currentTab==='EMBEDDED_PAGE' ? {component: AssetFileFrame} : null)
        || {component: () => null};
    const TabComponent = (currentTab?.parcelName ? (currentTab.type==='J' ? JewelleryDetails : currentTab.type==='LG' ? LabGrownDetails : StoneDetails) : currentTab?.component) ?? (() => null);

    return (
        <>
            <Sidebar 
                docked={true}
                sidebar={
                    <div className='left-panel'>
                        <img src={logo} className='dialog-logo' alt='' />
                        {showDefaultTabs.map(tab => renderNavItem(tab))}
                        {(props.extraTabList || []).map(tab => renderNavItem(tab))}
                        <div className='flexbox-filler' />
                        {showEndLinks.map(item => renderNavItem(item))}
                    </div>
                }
            >
                <Sidebar
                    open={profileOpen}
                    onSetOpen={setProfileOpen}
                    pullRight={true}
                    sidebar={<Profile closeDrawer={() => setProfileOpen(false)} />}
                    styles={{sidebar: {
                        zIndex: 4, 
                        backgroundColor: 'var(--primary-colour)', 
                        overflow: profileOpen ? 'visible' : 'hidden'
                    }, overlay: {zIndex: 3}}}
                >
                    <div 
                        className='small-right-bar' 
                        onClick={() => setProfileOpen(true)}
                    >
                        <div className='profile-handle'><i className='fas fa-caret-left'/> Profile</div>
                    </div>
                    <div className="main-panel__header"/>
                    <div className='main-panel'>
                        <ErrorBoundary>
                            <TabComponent parcelName={currentTab.parcelName} /> {/*parcelName will be ignored unless TabComponent is StoneDetails*/}
                        </ErrorBoundary>
                    </div>
                </Sidebar>
            </Sidebar>
            {
                props.mustChangePassword ? 
                <Modal open={true} onClose={() => {}} showCloseIcon={false} center>
                    <h4>Welcome to Dialog!</h4>
                    <h6>To begin, please choose a new password.</h6>
                    <ChangePassword username={props.username} changePassword={props.changePassword}/>
                </Modal>
                : null
            }
            <Loading />
            <ReactTooltip multiline={true} place='right' backgroundColor='var(--light-colour)' />
        </>
    )
}

function mapStateToProps(state) {
    if (!state.auth || !state.user || !state.user.currentUser) return {};
    return {
        username: state.user.currentUser.username,
        authenticated: state.auth.authenticated, 
        administrator: state.user.currentUser.administrator, 
        salesRep: state.user.currentUser.salesRep,
        mustChangePassword: state.user.currentUser.mustChangePassword,
        userFirstName: state.user.currentUser.firstName,
        userEmail: state.user.currentUser.email,
        extraTabList: state.tabs.list,
        currentTab: state.tabs.selected || 'My Inventory.Loose',
        currentCustomerId: state.user.customer ? state.user.customer.customerId : '',
        customerList: state.admin.customerList || null,
        allInventory: state.inventory || {},
        allJewellery: state.jewellery || {},
        allLabGrown: state.labGrown || {},
        allModels: {available: state.jewellery?.models} || {}
    };
  }

  export default connect(mapStateToProps, actions)(AppBase);